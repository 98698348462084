import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useGlobalModal } from "../../../contexts/GlobalModalContext";
import { PrimaryButtonStyled } from "../PrimaryButtonStyled";
import { OutlinedButtonStyled } from "../OutlinedButtonStyled";
import { markCompleteRoster, moveStatus } from "../../../api/RosterApi";
import { useAlert } from "../../../contexts/AlertsContext";
import {
    getJurisdictionStatusesByPersonnel,
    getNonSterlingFindingsFilenames,
    getPersonnelBgcFindingsReport,
} from "../../../api/PersonnelApi";
import { Col, Row } from "react-bootstrap";
import { RequiredLabel } from "../FormElements";
import { BestRadio, Icon, TextArea } from "best-common-react";
import { downloadFile, getFile } from "../../../api/FileApi";
import { PersonnelTypes } from "../../../constants/PersonnelTypes";
import FileUtil from "../../../util/FileUtil";
import FormUtil from "../../../util/FormUtil";

type ModalProps = {
    modalHeight: string;
};
const BodyContent = styled.div.attrs(() => ({}))<ModalProps>`
    text-align: left;
    margin: 0.7rem;
    padding: 0.5rem;
    height: ${props => props.modalHeight};
    color: var(--slate);
`;

const NameContent = styled.div`
    font-size: 16px;
    color: var(--slate);
`;

const OrganizationContent = styled.div`
    font-size: 12px;
    color: var(--slate);
`;

const ReportsContent = styled.div`
    border: solid 1px var(--fog);
    margin-top: 1rem;
    padding: 1rem;
    background-color: var(--cloud);
    height: 210px;
    overflow-x: hidden;
    overflow-y: scroll;
`;

const ReportItems = styled.div``;
const DownloadContent = styled.div`
    margin-top: 0.3rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    color: var(--blue);
`;
const CommentContent = styled.div`
    margin-top: 0.3rem;
`;

const BgcScore = styled.div`
    margin-top: 0.5rem;
`;

const StyledRadioButtonText = styled.label.attrs(() => ({
    className: "d-flex align-self-center mt-1 mr-3",
}))`
    font-family: Helvetica;
    font-weight: normal;
    font-size: 14px;
`;

const HoverIcon = styled(Icon)`
    width: 25px;
    height: 16px;
    color: var(--blue);
    cursor: pointer;
`;

const DownloadReport = styled.div`
    color: var(--blue);
    cursor: pointer;
`;
type ReviewFindingsModalBodyProps = {
    personnel: any;
    active: string;
    comment: string;
    setComment: Function;
    callback: Function;
    bgcScore: string;
    setBgcScore: Function;
    jurisdiction: string;
};

const ReviewFindingsModalBody: React.FC<ReviewFindingsModalBodyProps> = ({
    personnel,
    active,
    comment,
    setComment,
    callback,
    bgcScore,
    setBgcScore,
    jurisdiction,
}) => {
    const [bgcForm, setBgcForm] = useState({ filename: "" });
    const [downloadFiles, setDownloadFiles] = useState([]);
    const [reports, setReports] = useState([]);
    const readOnly = active && active.toUpperCase() === "INACTIVE" ? true : false;
    const [findingsJurisdiction, setFindingsJurisdiction] = useState<string>(jurisdiction);

    useEffect(() => {
        if (personnel.id > -1) {
            getJurisdictionStatusesByPersonnel([personnel]).then(response => {
                let jurisList = [];
                if (response && response.length > 0) {
                    const rosterId = response[0].personnel.roster.id;
                    jurisList = response[0].jurisdictionStatusList;
                    jurisList = jurisList
                        .filter((juris: any) => juris.status === "Review Findings")
                        .map((juris: any) => {
                            return {
                                name: juris.jurisdiction,
                                displayName: FormUtil.formatJurisdiction(juris.jurisdiction),
                                status: juris.status,
                                rosterId: rosterId,
                            };
                        });

                    // TODO -- Handle non US findings?
                    if (
                        !!jurisList &&
                        jurisList.length == 1 &&
                        (jurisList[0].name === "usPuerto" ||
                            personnel.personnelType.type === PersonnelTypes.UMPIRE)
                    ) {
                        setFindingsJurisdiction(jurisList[0].name);
                    }
                }
            });
        }
    }, [personnel]);

    useEffect(() => {
        if (!!findingsJurisdiction) {
            // Handle by Sterling, so get report from Sterling
            getPersonnelBgcFindingsReport(personnel.id, findingsJurisdiction).then(bgcForm => {
                setBgcForm({ filename: bgcForm.filename });
                const reports = [];
                let index = 1;
                for (const reportCategory in bgcForm.formData) {
                    const result = bgcForm.formData[reportCategory].result;
                    if (Object.keys(result).length === 0) {
                        continue;
                    }
                    const report = {};
                    report["id"] = index++;
                    report["name"] = reportCategory;
                    report["request"] = bgcForm.formData[reportCategory].request;
                    report["result"] = result;

                    reports.push(report);
                }
                setReports(reports);
                setDownloadFiles([bgcForm.filename]);
            });
        } else {
            // Non-sterling handled jurisdiction, get filenames from Admin uploaded files.
            getNonSterlingFindingsFilenames(personnel.id, findingsJurisdiction).then(files => {
                setDownloadFiles(files);
            });
        }

        //When readOnly, the comment is actually stored in status "Findings Complete"
        //Need to get comment from there.
        if (readOnly) {
            const statuses = personnel?.roster?.statuses;
            const findingCompletedStatus = statuses?.find(
                (s: any) =>
                    s.country === jurisdiction &&
                    s.status.status.toUpperCase() === "FINDINGS COMPLETE",
            );
            if (findingCompletedStatus) {
                setComment(findingCompletedStatus.comment);
            }
        }
    }, [findingsJurisdiction, personnel]);

    const commentHandler = (event: any) => {
        setComment(event.currentTarget.value);
    };

    const downloadReportHandler = (fileList: string[]) => {
        fileList.forEach((filename: string) => {
            const filePromise =
                findingsJurisdiction === "usPuerto"
                    ? downloadFile(filename)
                    : getFile(personnel.id, filename);

            filePromise.then(data => {
                FileUtil.openFileInNewTab(data);
            });
        });
    };

    return (
        <BodyContent
            modalHeight={findingsJurisdiction?.toUpperCase() === "USPUERTO" ? "450px" : "200px"}
        >
            <NameContent>
                {personnel.lastName}, {personnel.firstName} - {personnel.title}
            </NameContent>
            <OrganizationContent>
                {personnel.affiliation.orgAbbreviation} - {personnel.affiliation.name}
            </OrganizationContent>
            {findingsJurisdiction?.toUpperCase() === "USPUERTO" && (
                <ReportsContent>
                    {reports.map(report => (
                        <div key={report.id}>
                            <div>
                                <span>{report.id}.)</span>
                                <span className="pl-2 text-dark font-weight-bold">
                                    {report.name}
                                </span>
                            </div>
                            <ReportItems>
                                {Object.keys(report.result).map((keyName, idx) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <Row key={idx}>
                                        <Col xs={1} />
                                        <Col xs={2}>{keyName}</Col>
                                        <Col>{report.result[keyName]}</Col>
                                    </Row>
                                ))}
                            </ReportItems>
                        </div>
                    ))}
                </ReportsContent>
            )}

            <DownloadContent>
                <HoverIcon
                    iconName="fa-download"
                    onClick={() => {
                        downloadReportHandler(downloadFiles);
                    }}
                />
                <DownloadReport
                    onClick={() => {
                        downloadReportHandler(downloadFiles);
                    }}
                >
                    Download Report
                </DownloadReport>
            </DownloadContent>
            <CommentContent>
                <RequiredLabel text="Comments" required={readOnly ? false : true} />
                <TextArea
                    disabled={readOnly}
                    placeholder="enter comments..."
                    value={comment}
                    onChange={commentHandler}
                    style={{
                        resize: "none",
                        fontSize: "14px",
                        color: "var(--black)",
                        fontWeight: "300",
                        borderColor: "var(--fog)",
                    }}
                />
            </CommentContent>
            {(personnel.personnelType.type === PersonnelTypes.VMS ||
                personnel.personnelType.type === PersonnelTypes.UMPIRE) && (
                <BgcScore>
                    <RequiredLabel text="BGC Score" required />
                    <div className="d-flex align-items-center">
                        <BestRadio
                            id="radioPass"
                            onChange={() => {
                                setBgcScore("PASS");
                            }}
                            checked={bgcScore.toUpperCase() === "PASS"}
                            disabled={readOnly}
                        />
                        <StyledRadioButtonText htmlFor="radioPass">Pass</StyledRadioButtonText>
                        <BestRadio
                            id="radioFail"
                            onChange={() => {
                                setBgcScore("FAIL");
                            }}
                            checked={bgcScore.toUpperCase() === "FAIL"}
                            disabled={readOnly}
                        />
                        <StyledRadioButtonText htmlFor="radioFail">Fail</StyledRadioButtonText>
                    </div>
                </BgcScore>
            )}
        </BodyContent>
    );
};

const ReviewFindingsModalFooter: React.FC<ReviewFindingsModalBodyProps> = ({
    personnel,
    active,
    comment,
    setComment,
    callback,
    bgcScore,
    setBgcScore,
}) => {
    const { closeModal } = useGlobalModal();
    const showAlert = useAlert();
    const readOnly = active && active.toUpperCase() === "INACTIVE" ? true : false;
    const markComplete = () => {
        // ToDo: Currently only handles usPuerto. Need to revisit to handle other jurisdictions.
        const findingsStatus = personnel.roster.statuses.filter(
            (s: any) =>
                s.active === "ACTIVE" &&
                (s.country === "usPuerto" ||
                    (s.country === "other" && personnel.umpire === true)) &&
                s?.status?.status === "Findings",
        );

        if (!findingsStatus || findingsStatus.length === 0 || findingsStatus.length > 1) {
            showAlert("Mark Complete failed.", "danger");
        } else {
            const markCompleteDto = {
                rosterStatusId: findingsStatus[0].id,
                comment: comment,
                bgcScore: bgcScore,
            };
            markCompleteRoster(personnel.roster.id, markCompleteDto).then(result => {
                if (result && result.status === "SUCCEEDED") {
                    showAlert("Complete");
                    if (callback && typeof callback === "function") {
                        callback();
                    }
                } else {
                    showAlert("Mark Complete failed.", "danger");
                }
            });
        }
        setComment("");
    };

    return (
        <div className="d-flex">
            {!readOnly && (
                <PrimaryButtonStyled
                    width={142}
                    height={26}
                    disabled={!comment || !bgcScore}
                    onClick={event => {
                        event.stopPropagation();
                        markComplete();
                        closeModal();
                    }}
                >
                    Mark Complete
                </PrimaryButtonStyled>
            )}

            <div className="pl-2">
                <OutlinedButtonStyled
                    width={142}
                    height={26}
                    disabled={false}
                    onClick={event => {
                        event.stopPropagation();
                        closeModal();
                    }}
                >
                    {readOnly ? "Close" : "Cancel"}
                </OutlinedButtonStyled>
            </div>
        </div>
    );
};

ReviewFindingsModalBody.displayName = "BodyContent";
ReviewFindingsModalFooter.displayName = "FooterContent";

export { ReviewFindingsModalBody, ReviewFindingsModalFooter };
