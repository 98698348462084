import { usStatesOptions } from "../../../../questionnaire/dropdownOptionDefs/UsStateOptions";

export type UmpireGuestInfoLayoutRowType = {
    id: number;
    label: string;
    name: string;
    required(state: any): boolean;
    type: string;
    dependencies?: Array<string>;
    show(state: any): boolean;
};

export const UmpireGuestInfoLayoutRows = {
    1: [
        {
            id: 24,
            label: "Umpire Name",
            name: "affiliateId",
            required: (state: any) => {
                return true;
            },
            type: "umpire",
            show: (state: any) => {
                return true;
            },
        },
        {
            id: 25,
            label: "Relationship to Umpire",
            name: "title",
            required: (state: any) => {
                return true;
            },
            type: "input",
            show: (state: any) => {
                return true;
            },
        },
    ],
    2: [
        {
            id: 1,
            label: "First Name",
            name: "firstName",
            required: (state: any) => {
                return true;
            },
            type: "input",
            show: (state: any) => {
                return true;
            },
        },
        {
            id: 2,
            label: "Middle Name",
            name: "middleName",
            required: (state: any) => {
                return false;
            },
            type: "input",
            show: (state: any) => {
                return true;
            },
        },
        {
            id: 3,
            label: "Last Name",
            name: "lastName",
            required: (state: any) => {
                return true;
            },
            type: "input",
            show: (state: any) => {
                return true;
            },
        },
    ],
    3: [
        {
            id: 4,
            label: "Birth Date",
            name: "birthDate",
            required: (state: any) => {
                return true;
            },
            type: "date",
            show: (state: any) => {
                return true;
            },
        },
        {
            id: 5,
            label: "SSN",
            name: "ssn",
            required: (state: any) => {
                return true;
            },
            type: "ssn",
            show: (state: any) => {
                return true;
            },
        },
    ],
    4: [
        {
            id: 6,
            label: "Home Address",
            name: "homeAddress",
            required: (state: any) => {
                return true;
            },
            type: "input",
            show: (state: any) => {
                return true;
            },
        },
    ],
    5: [
        {
            id: 7,
            label: "City",
            name: "city",
            required: (state: any) => {
                return true;
            },
            type: "input",
            show: (state: any) => {
                return true;
            },
        },
        {
            id: 8,
            label: "State",
            name: "state",
            required: (state: any) => {
                return true;
            },
            options: usStatesOptions,
            type: "dropdown",
            show: (state: any) => {
                return true;
            },
        },
        {
            id: 9,
            label: "Zip Code",
            name: "zipCode",
            numsOnly: true,
            required: (state: any) => {
                return true;
            },
            type: "input",
            show: (state: any) => {
                return true;
            },
        },
    ],
    6: [
        {
            id: 10,
            label: "Previous Address",
            name: "previousAddress",
            required: (state: any) => {
                return false;
            },
            type: "input",
            show: (state: any) => {
                return true;
            },
        },
    ],
    7: [
        {
            id: 11,
            label: "City",
            name: "previousCity",
            required: (state: any) => {
                return false;
            },
            type: "input",
            show: (state: any) => {
                return true;
            },
        },
        {
            id: 12,
            label: "State",
            name: "previousState",
            required: (state: any) => {
                return false;
            },
            options: usStatesOptions,
            type: "dropdown",
            show: (state: any) => {
                return true;
            },
        },
        {
            id: 13,
            label: "Zip Code",
            name: "previousZipCode",
            numsOnly: true,
            required: (state: any) => {
                return false;
            },
            type: "input",
            show: (state: any) => {
                return true;
            },
        },
    ],
    8: [
        {
            id: 14,
            label: "Shipping Address",
            name: "shippingAddress",
            required: (state: any) => {
                return true;
            },
            type: "input",
            show: (state: any) => {
                return true;
            },
        },
    ],
    9: [
        {
            id: 15,
            label: "City",
            name: "shippingCity",
            required: (state: any) => {
                return true;
            },
            type: "input",
            show: (state: any) => {
                return true;
            },
        },
        {
            id: 16,
            label: "State",
            name: "shippingState",
            required: (state: any) => {
                return true;
            },
            options: usStatesOptions,
            type: "dropdown",
            show: (state: any) => {
                return true;
            },
        },
        {
            id: 17,
            label: "Zip Code",
            name: "shippingZipCode",
            numsOnly: true,
            required: (state: any) => {
                return true;
            },
            type: "input",
            show: (state: any) => {
                return true;
            },
        },
    ],
    10: [
        {
            id: 18,
            label: "Phone Number",
            name: "phoneNumber",
            required: (state: any) => {
                return true;
            },
            type: "input",
            show: (state: any) => {
                return true;
            },
        },
        {
            id: 19,
            label: "Driver's License",
            name: "driversLicenseState",
            required: (state: any) => {
                return false;
            },
            options: usStatesOptions,
            type: "dropdown",
            show: (state: any) => {
                return true;
            },
        },
        {
            id: 20,
            label: "Driver's License Number",
            name: "driversLicenseNumber",
            numsOnly: true,
            required: (state: any) => {
                return false;
            },
            type: "input",
            show: (state: any) => {
                return true;
            },
        },
    ],
    11: [
        {
            id: 21,
            label: "add additional search names",
            name: "additionalSearchNames",
            required: (state: any) => {
                return false;
            },
            type: "link",
            show: (state: any) => {
                return true;
            },
        },
    ],
    12: [
        {
            id: 22,
            label: "Have you lived in any country other than the one you currently reside in?",
            name: "livedInOtherCountry",
            required: (state: any) => {
                return true;
            },
            type: "radio",
            dependencies: [
                "otherCountryLivedIn",
                "otherCountryLivedInFrom",
                "otherCountryLivedInTo",
                "otherCountryLivedInWhere",
            ],
            show: (state: any) => {
                return true;
            },
        },
    ],
    13: [
        {
            id: 23,
            label: "Country",
            name: "otherCountryLivedIn",
            required: (state: any) => {
                return state.livedInOtherCountry;
            },
            type: "input",
            show: (state: any) => {
                return state.livedInOtherCountry;
            },
        },
        {
            id: 24,
            label: "From",
            name: "otherCountryLivedInFrom",
            required: (state: any) => {
                return state.livedInOtherCountry;
            },
            type: "input",
            show: (state: any) => {
                return state.livedInOtherCountry;
            },
        },
        {
            id: 25,
            label: "To",
            name: "otherCountryLivedInTo",
            required: (state: any) => {
                return state.livedInOtherCountry;
            },
            type: "input",
            show: (state: any) => {
                return state.livedInOtherCountry;
            },
        },
    ],
    14: [
        {
            id: 26,
            label: "Where in the country did you live?",
            name: "otherCountryLivedInWhere",
            required: (state: any) => {
                return state.livedInOtherCountry;
            },
            type: "input",
            show: (state: any) => {
                return state.livedInOtherCountry;
            },
        },
    ],
};

export const UmpireGuestInfoDrVenColRow = [
    {
        id: 1,
        label: "Middle Name",
        name: "middleName",
        required: (state: any) => {
            return false;
        },
        type: "input",
        show: (state: any) => {
            return state.isDrVenCol;
        },
    },
    {
        id: 2,
        label: "Mother's Maiden Name",
        name: "maidenName",
        required: (state: any) => {
            return false;
        },
        type: "input",
        show: (state: any) => {
            return state.isDrVenCol;
        },
    },
];

export const UMPIRE_GUEST_INFO_LAYOUT_ROWS_AMT = 12;
